<template>
    <div>
        <!-- header -->
        <div class="w-full overflow-hidden bg-primary relative">
            <div v-if="headerSect">
                <div class="md:flex pt-4 sm:pt-0 relative z-10">
                    <div class="w-full sm:w-1/2 text-center text-white pt-20 pb-8 sm:py-32 md:py-48 lg:py-52 xl:py-64 bg-primary">
                        <h1 class="block h1 mt-4 trans-opacity max-w-xs lg:max-w-sm mx-auto px-4">
                            <span>{{ strings.title }}</span>
                            <span class="trans-opacity" :class="{ 'opacity-0': loading || !user.name }">
                                {{ user && user.name ? `, ${user.name}` : "" }}
                            </span>
                        </h1>
                    </div>
                    <div class="absolute left-0 w-full sm:w-1/2 flex sm:hidden md:flex bottom-0 h-16 justify-center">
                        <div class="w-8 h-16 mt-64 sm:mt-0" style="transform: translateY(-1.5rem)">
                            <svg viewBox="0 0 30 41" class="fill-current text-white use-svg">
                                <path d="M15.2857 40L1 25.7746M15.2857 40L29.5714 25.7746M15.2857 40V0" />
                            </svg>
                        </div>
                    </div>
                </div>
                <!-- image -->
                <div
                    class="w-full h-64 sm:h-full top-0 static sm:absolute order-1"
                    :style="`left: 50%; background: ${headerSect.image_fadeto_colour}`"
                >
                    <div class="w-full lg:w-4/5 xxxl:w-full relative h-full sm:-ml-24 xxxl:ml-0" style="max-width: 960px">
                        <LazyLoadingBGimage
                            dont_fade_in
                            backgroundSizePosClasses="bg-cover sm:bg-contain bg-center-top sm:bg-left-bottom"
                            :mainSrc="headerSect.image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <transition-group name="portal-fade">
            <div v-if="loading" class="absolute py-24 left-0 w-full">
                <p class="sr-only">{{ strings.loading }}</p>
                <LoadingSpinner borderColourClass="border-mid-grey" class="mx-auto" />
            </div>
            <div v-else>
                <!-- latest blog content -->
                <section class="max-w-2xl mx-auto px-2 md:px-4 mt-20 sm:mt-24">
                    <h2 class="h2 text-primary max-w-sm ml-4 mb-20">{{ strings["update-message"] }}</h2>
                    <Carousel
                        v-if="blogsToShow.length > 0"
                        :totalSlides="blogsToShow.length"
                        :perPageAtWidths="{ 0: 1, 576: 2, 768: 3, 992: 4 }"
                        showPrevNext
                        name="latest-blogs"
                        class="max-w-2xl mx-auto"
                    >
                        <template v-for="(blog, index) in blogsToShow" v-slot:[`slot-${index}`]="blogsToShow" :key="`blog-slide-${index}`">
                            <div class="flex justify-center" style="min-height: inherit">
                                <BlogCard :blogData="blog" withImage bgColourClass="bg-primary text-white" style="min-height: inherit" />
                            </div>
                        </template>
                    </Carousel>
                    <!-- survey opportunities (coming soon) - will need updating to truly pull available surveys -->
                    <Carousel
                        :totalSlides="2"
                        :perPageAtWidths="{ 0: 1, 576: 1, 768: 1, 992: 2 }"
                        showPrevNext
                        name="latest-surveys"
                        class="max-w-2xl mx-auto mt-8"
                    >
                        <template
                            v-for="(survey, index) in ['placeholder-a', 'placeholder-b']"
                            v-slot:[`slot-${index}`]
                            :key="`survey-slide-${index}`"
                        >
                            <div class="flex justify-center items-center bg-lightest-grey rounded-md py-32" style="min-height: inherit">
                                <div class="w-full max-w-xs text-center text-base">
                                    <div class="w-5 h-6 mx-auto">
                                        <svg class="use-svg fill-current text-primary">
                                            <use xlink:href="#survey-opportunity" />
                                        </svg>
                                    </div>
                                    <p class="mt-2">{{ strings["survey-opps"] }}</p>
                                    <p class="text-mid-grey high-c-text-black">{{ strings["coming-soon"] }}</p>
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </section>
                <section class="mt-20 sm:mt-24 relative overflow-visible">
                    <div
                        class="bg-gradient-to-r from-tertiary-a to-tertiary-b absolute top-0 h-full left-0 w-full"
                        style="transform: translateY(50%)"
                    ></div>
                    <div class="max-w-2xl mx-auto px-2 md:px-4 relative z-10">
                        <h2 class="h2 text-primary max-w-sm ml-4 mb-20">{{ strings["research-title"] }}</h2>
                        <!-- reaserch opportunities (coming soon - studies) - will also need updating after this section is added & launched (likely later on than surveys) -->
                        <Carousel
                            :totalSlides="2"
                            :perPageAtWidths="{ 0: 1, 576: 1, 768: 1, 992: 2 }"
                            showPrevNext
                            name="latest-studies"
                            class="max-w-2xl mx-auto mt-8"
                        >
                            <template
                                v-for="(study, index) in ['placeholder-a', 'placeholder-b']"
                                v-slot:[`slot-${index}`]
                                :key="`study-slide-${index}`"
                            >
                                <div class="flex justify-center items-center bg-lightest-grey rounded-md py-32" style="min-height: inherit">
                                    <div class="w-full max-w-xs text-center text-base">
                                        <div class="w-5 h-6 mx-auto">
                                            <svg class="use-svg fill-current text-primary">
                                                <use xlink:href="#personalised" />
                                            </svg>
                                        </div>
                                        <p class="mt-2">{{ strings["research-opps"] }}</p>
                                        <p class="text-mid-grey high-c-text-black">{{ strings["coming-soon"] }}</p>
                                    </div>
                                </div>
                            </template>
                        </Carousel>
                    </div>
                </section>
            </div>
        </transition-group>
    </div>
</template>

<script>
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage";
import BlogCard from "@/components/cards/blogCard";
import Carousel from "@/components/ui/carousel";
import LoadingSpinner from "@/components/ui/loadingSpinner";
import { User, Blogpost } from "@/data/models";
/* See loggedInPagesText.js */
import text from "./homeText";

export default {
    name: "Home",
    data() {
        return {
            loading: true,
            strings: text,
        };
    },
    components: {
        BlogCard,
        Carousel,
        LoadingSpinner,
        LazyLoadingBGimage,
    },
    computed: {
        headerSect() {
            return this.portalConfig?.landing?.header;
        },
        aboutSect() {
            return this.portalConfig?.landing?.about;
        },
        user() {
            return User.query().first();
        },
        blogsToShow() {
            const time_now = new Date().getTime();
            return Blogpost.query()
                .where("date_published", (dt) => new Date(dt).getTime() < time_now)
                .where("published", true)
                .orderBy("date_published", "desc")
                .limit(10)
                .get();
        },
    },
    async mounted() {
        await Promise.all([Blogpost.$read(), this.$api.user.read_user()]);
        this.loading = false;
    },
};
</script>

<style scoped>
.header-img {
    width: 105%;
    left: 0;
    height: 160%;
    top: -30%;
}

@media (min-width: 1200px) {
    .header-img {
        left: 5%;
    }
}

@media (max-width: 991px) {
    .header-img {
        top: -28%;
        left: -10%;
        width: 126%;
    }
}

@media (max-width: 767px) {
    .header-img {
        @apply max-w-sm h-48;
    }
}
</style>
